<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>{{ slug == 0 ? $t("Our Production") : $getByLang(name) }}</h1>
        </div>
      </div>
    </section>

    <div class="container ">
      <div class="row mb-5" v-if="list && list.length > 0">
        <div
          class="col-md-4 col-sm-12 col-xs-12 col-lg-4"
          v-for="item of list"
          :key="item.id"
        >
          <div class="ed-course-in">
            <a
              v-if="item.slug.startsWith('http')"
              :href="item.slug"
              class="course-overlay"
              target="_blank"
            >
              <img class="mimg" :src="$baseUploadURL + item.image" alt="" />
              <span
                >{{ cattype == 1 ? item.pname : $getByLang(item.name) }}
                {{ cattype == 1 ? "(" + item.price + "€)" : "" }}</span
              >
            </a>
            <router-link
              v-else
              :to="'/Production/' + item.slug"
              class="course-overlay"
            >
              <img class="mimg" :src="$baseUploadURL + item.image" alt="" />
              <span
                >{{ cattype == 1 ? item.pname : $getByLang(item.name) }}
                {{ cattype == 1 ? "(" + item.price + "€)" : "" }}</span
              >
            </router-link>

            <i
              v-if="cattype == 1"
              @click="push(item)"
              class="fa fa-cart-plus"
              style="position: absolute;top: 1%;right: 4%;z-index: 1;color: #fff;font-weight: 400;font-size: 2.765em;"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      cattype: null,
      slug: null,
      list: [],
    };
  },
  methods: {
    push(item) {
      let itemsList = localStorage.itemsList
        ? JSON.parse(localStorage.itemsList)
        : [];

      let index = itemsList.findIndex((i) => i.id == item.id);

      if (index == -1) {
        itemsList.push({
          ...item,
          quantity: 1,
        });
      } else {
        itemsList[index].quantity = itemsList[index].quantity + 1;
      }
      localStorage.itemsList = JSON.stringify(itemsList);
      this.$eventHub.$emit("addItem", {});
    },
    getData(slug) {
      this.$http
        .post("categories/findOne", {
          slug,
        })
        .then(
          (res) => {
            if (slug != null) {
              this.name = res.data.data.name;
              this.cattype = res.data.data.cattype;
              // if (this.cattype == 1) {
              //   this.$http
              //     .post("products/search?limit=10000000", {
              //       categoriesId: res.data.data.id,
              //     })
              //     .then((res2) => {
              //       this.list = res2.data.data.items;
              //     });
              // } else {
              this.$http
                .post("categories/search?limit=10000000?linkOrSection=link", {
                  parntId: res.data.data.id,
                })
                .then((res2) => {
                  this.list = res2.data.data.items;
                });
              // }
            } else {
              this.$http
                .post("categories/search?limit=10000000", {
                  parntId: null,
                })
                .then((res2) => {
                  this.list = res2.data.data.items;
                });
            }
          },
          (err) => {
            console.log(err);
          }
        );
    },
  },

  created() {
    this.slug = this.$route.params.slug;
    if (this.slug == 0) {
      this.getData(null);
    } else {
      this.getData(this.slug);
    }
  },
  watch: {
    "$route.params.slug"(newv) {
      if (newv == 0) {
        this.getData(null);
      } else {
        this.getData(newv);
      }
    },
  },
  head: {
    // title: {
    //   inner: 'Offers',
    // },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>
